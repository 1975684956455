import React, {useEffect, useState} from 'react';
//For ECM
//import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from './components';
import { StoreProvider,useStore } from './store';
import { ViewPage } from './cms/pages/viewPage/viewPage';
import { EditPage } from './cms/pages/editPage/editPage';
import { TopHeader } from './layout/topHeader/topHeader';
// import { Footer } from './layout/footer/footer';
import { BottomDock } from './layout/bottomDock/bottomDock';
// import { BlogView } from './site/blog/blogView';
import { AppVersionPage } from './components';
import { MediaQueryStatus } from './components';
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from 'react-helmet-async';
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AppRouteChanged } from './AppRouteChanged';
import AOS from 'aos';
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";
import { Item } from './cms/items/item';

//For ECM
// import { ProductView } from './site/ecm/product/productView';
// import { OrderDetails } from './site/ecm/orderDetails/orderDetails';
// import env from './env';
// import { useStore } from './store';

function Portal({children}){
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
      let mount = document.getElementById("portal-root");
      mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

function AppLayout(props) {
  const { pathname } = useRouter();
  const { state, dispatch } = useStore();

  //For ECM
  //const storeContext = useRef(useStore());
  
  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  // useScrollPosition(({ prevPos, currPos }) => {
  //   //console.log(currPos.x)
  //   //console.log(currPos.y);
  //   if (currPos.y > 90) {
  //     document.querySelector('body').classList.add('scrolled')
  //   } else {
  //     document.querySelector('body').classList.remove('scrolled')
  //   }
  //   //setScrolled(currPos.y > 90)
  // }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))
  
  //const pathFiltered = (pathname || '').toLocaleLowerCase();
  let specialClassName = '';
//  if (pathFiltered.indexOf('/digital-sensor') >= 0) specialClassName = 'digital-sensor-page';
//  else if (pathFiltered === '/brilliance') specialClassName = 'brilliance-page';

useEffect(()=>{
  AOS.init({
    duration : 1200
    //delay:400,
    //once:true
  });
  // For ECM
  // fetch(env.apiBase + "/api/cart/getcart/" + env.getDevCartId())
  //   .then(res => res.json())
  //   .then(res => {
  //       const data = res;
  //       if(data){
  //         storeContext.current.dispatch({type:'SET_STATE', payload : {cartInfo : data.cartInfo.totalQuantity === 0 ? 0 : (data.cartInfo.totalQuantity)-1}});
  //       }
  //   })
  //   .catch(error =>{
  //     storeContext.current.dispatch({type:'SET_STATE', payload : {cartInfo : 0}});
  // });
}, []);

useEffect(()=>{
  window.closePopup = function() {
    const e = document.createEvent("MouseEvents");
    e.initEvent("click", true, false);
    document.querySelector('.close-btn svg').dispatchEvent(e)
  }
}, []);

  return (
    <div className={`body-content ${pathname === '/' ? 'homepage' : 'subpage'} ${specialClassName}`}>
      <AppRouteChanged />
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}
            
            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>

            {/* For ECM <Route path="/order-thankyou" children = {<OrderDetails />}/>
            <Route path="/product/:slug" children = {<ProductView />}/> */}

            <Route path="/">
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />
      
      {
        (state.popuppage && state.popuppage.open === true) &&
        <Portal>
          <div className="popup-page-screen">
            <div className="popup-page-container">
              <div className="close-btn"><MdClose onClick={(e) => {
                document.getElementsByTagName("body")[0].style.overflow = "auto";
                dispatch({type:'SET_STATE', payload : {popuppage : { ...state.popuppage, open : false } }});
                }}></MdClose>
              </div>
              <div className={`cmsPage cmsPage--${state.popuppage.data.pageTypeCode}`}>
              {
                // popupPageData.sections.map((section, index) => {
                //   return(
                //     <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>
                //   )
                // })
                state.popuppage.data.sections.map((section, index) => {
                  return(
                    <Section item={section} key={section.sectionName + index} cmsOption={state.popuppage.cmsOption}></Section>
                  )
                })
              }
            </div>
            </div>
        </div>
        </Portal>
      }
    </div>
  );
}

function Section(props) {
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0)
    return (<></>);
  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <>
      {subItemsElts}
    </>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
      <Router>
        <AppLayout />
      </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
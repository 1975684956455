export function overlay() {
  return {
    content: `''`,
    position: 'absolute',
    top:0,
    bottom:0,
    left: 0,
    right: 0,
    transition: 'all 0.5s'
  }
}
import React from 'react';
import env from '../../env';

export function MediaQueryStatus(props) {
  return (
    <div className="devMediaQueryStatus" style={{display: env.isProd ? 'none' : 'block'}}>
      <div className="devMediaQueryStatus__current"></div>
    </div>
  );
}

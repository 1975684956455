import facepaint from 'facepaint'
import siteConfig from '../../config';

const config = siteConfig.cssEmotion
const breakpointNames = (config && config.breakpointNames) || ['sm', 'md', 'lg', 'xl', 'xxl']
const breakpoints = (config && config.breakpoints) || [576, 768, 992, 1200, 1600]

const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

const mqMin = {};
breakpointNames.forEach((bp, index) => mqMin[bp] = `@media (min-width: ${breakpoints[index]}px)`)

const mqMax = {};
breakpointNames.forEach((bp, index) => mqMax[bp] = `@media (max-width: ${breakpoints[index] - 1}px)`)
//console.log(mqMin, mqMax)

const matches = (mediaQuery) => !!window.matchMedia(mediaQuery).matches
const isMin = {};
breakpointNames.forEach((bp, index) => isMin[bp] = () => matches(`(min-width: ${breakpoints[index]}px)`))

const isMax = {};
breakpointNames.forEach((bp, index) => isMax[bp] = () => matches(`(max-width: ${breakpoints[index] - 1}px)`))

export { mq, mqMin, mqMax, isMin, isMax }
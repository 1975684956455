const initialState = {
  page: null,
  menu: null,
  popup: null,
  history: null
};

const reducer = (state = initialState, action) => {
  //console.log('reducer', action)
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload
      };
    case 'SET_MENU':
      return {
        ...state,
        menu: action.payload
      };
    case 'SET_POPUP':
      return {
        ...state,
        popup: action.payload
      };
    case 'SET_HISTORY':
      return {
        ...state,
        history: action.payload
      };
    case 'SET_STATE':
      return {
        ...state,
        ...action.payload
      };
    default:
      throw new Error("Unexpected action");
  }
};

export { initialState, reducer };
//#region GTM
export const hasGTM = () => {
  return !!(typeof window !== `undefined` && window.dataLayer);
}

export const pageView = (url, pageTitle = null) => {
  if (!hasGTM()) return;

  const pageOpt = pageTitle ? {
    path: url,
    title: pageTitle
  } : { path: url }
  //console.log(url, pageOpt)
  window.dataLayer.push({
    event: 'pageview',
    page: pageOpt
  })
}

// 
export const sendEvent = (eventName, params) => {
  if (!hasGTM()) return;
  window.dataLayer.push(Object.assign({ event: eventName }, params || {}))
}

export const sendGAEvent = (category, action, label, value) => {
  if (!hasGTM()) return;
  window.dataLayer.push(Object.assign({ event: 'gaEvent' }, { category: category || '', action: action || '', label: label || '', value: value || '' }))
}
//#endregion



export function findControl(controlNameOrIndex, root) {
  if (typeof controlNameOrIndex === 'number' && root && root.controls.length > controlNameOrIndex) return root.controls[controlNameOrIndex];
  return findControlTraverse(controlNameOrIndex, root);
}

function findControlTraverse(controlName, control) {
  if (!control) return null;
  if (control.name === controlName) return control;
  if (control.controls && control.controls.length > 0) {
    for (let i = 0; i < control.controls.length; i++) {
      const found = findControlTraverse(controlName, control.controls[i]);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function findControlById(id, root) {
  return findControlByIdTraverse(id, root);
}

function findControlByIdTraverse(id, control) {
  if (!control) return null;
  if (control.id === id) return control;
  if (control.controls && control.controls.length > 0) {
    for (let i = 0; i < control.controls.length; i++) {
      const found = findControlByIdTraverse(id, control.controls[i]);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function getValue(root) {
  //console.log(root)
  return getValueTraverse(root);
}

const helperFns = {
  getValueTraverse: getValueTraverse,
  getFilesTraverse: getFilesTraverse
}

function getKeyValueTraverse(control) {
  let keyValue = {};
  keyValue[control.name] = getValueTraverse(control);
  return keyValue
}

function getValueTraverse(control) {
  //console.log(control);
  if (control.controlType === 'control') {
    if (control.customValueFn)
      return control.customValueFn(control, helperFns);

    return control.value;
  } else if (control.controlType === 'group') {
    if (control.customValueFn)
      return control.customValueFn(control, helperFns);

    let value = {};
    for (let i = 0; i < control.controls.length; i++) {
      value = { ...value, ...getKeyValueTraverse(control.controls[i]) };
    }
    return value;
  } else if (control.controlType === 'array') {
    if (control.customValueFn)
      return control.customValueFn(control, helperFns);

    const array = [];
    for (let i = 0; i < control.controls.length; i++) {
      array.push(getValueTraverse(control.controls[i]))
    }
    return array;
  }
  return null;
}

export function getFiles(root) {
  //console.log(root)
  return getFilesTraverse(root);
}

function getFilesTraverse(control) {
  if (control.controlType === 'control') {
    return control.files ? [{ name: control.name, files: control.files }] : [];
  } else if (control.controlType === 'group') {
    let files = [];
    for (let i = 0; i < control.controls.length; i++) {
      files.push(...getFilesTraverse(control.controls[i]));
    }
    return files;
  } else if (control.controlType === 'array') {
    let files = [];
    for (let i = 0; i < control.controls.length; i++) {
      files.push(...getFilesTraverse(control.controls[i]));
    }
    return files;
  }
  return [];
}
import { useState } from 'react';
import controlRenderer from './controlRenderer';
import * as formModelHelper from './formModelHelper';
import * as formValidate from './formValidate';
import validators from './validators';

const useForm = (initialFormModel, option) => {
  const [formModel, setFormModel] = useState(initialFormModel);
  const formOption = {
    ...{
      template: 'bootstrap',
      usePlaceholder: false,
      requiredMark: '*',
      onControlChanged: null
    }, ...option
  };

  const handleChange = (e, controlFromEvent) => {
    e.persist();
    const control = controlFromEvent || formModelHelper.findControl(e.target.name, formModel);
    //console.log(control)
    // const control = controlId? formModelHelper.findControlById(controlId, formModel)
    //   : formModelHelper.findControl(e.target.name, formModel);
    if (!control) return;

    //console.log(control.value, e.target)
    control.touched = true;
    control.value = getControlValue(control, e.target);
    if (control.type === 'file') {
      control.files = e.target.files
    }

    // parseInput(control);
    if (control.onBeforeUpdateValue) {
      control.onBeforeUpdateValue(control, formModel)
    }

    validators.validateControl(control, e, { triggeredByChange: true });
    setFormModel({ ...formModel });

    if (formOption.onControlChanged) {
      formOption.onControlChanged(e, control)
    }
  };

  const validateForm = (e, successHandler, errorHandler) => {
    validateGroup(formModel, e, successHandler, errorHandler)
  };

  const validateGroup = (control, e, successHandler, errorHandler) => {
    e && e.preventDefault();
    const errors = formValidate.validate(control);

    if (errors.length === 0) successHandler && successHandler();
    else {
      setFormModel({ ...formModel });
      errorHandler && errorHandler(errors)
    }
  };

  const reset = (model) => setFormModel(model ? { ...model } : { ...formModel });

  // const parseInput = input => input.value = input.parseFun ? input.parseFun(input.value) : input.value;

  const renderControl = (name, formRoot) => controlRenderer.renderControl(formRoot || formModel, name, handleChange, formOption);
  const renderControlDirect = (control) => controlRenderer.renderControlDirect(control, handleChange, formOption);

  const getValue = (formRoot) => formModelHelper.getValue(formRoot || formModel);

  const getFiles = (formRoot) => formModelHelper.getFiles(formRoot || formModel);

  const findControl = (name, formRoot) => {
    const control = formModelHelper.findControl(name, formRoot || formModel);
    return control;
  }
  //return [controls, handleChange, handleSubmit, getValue]
  return {
    model: formModel,
    errors: formModel.errors,
    reset: reset,
    renderControl: renderControl,
    renderControlDirect: renderControlDirect,
    findControl: findControl,
    validateForm: validateForm,
    validateGroup: validateGroup,
    getValue: getValue,
    getFiles: getFiles
  }
};

function getControlValue(control, el) {
  //console.log(control, el)
  if (control.type === 'checkboxList') {
    const options = el.closest('.controlOptions');
    if (options) {
      return [...options.querySelectorAll('input:checked')].map(x => x.value)
    }
    return [];
  }
  else if (control.type === 'radioList') {
    const options = el.closest('.controlOptions');
    if (options) {
      const val = [...options.querySelectorAll('input:checked')].map(x => x.value)
      return val && val.length > 0 ? val[0] : null
    }
    return null;
  }
  return control.type === 'checkbox' ? el.checked : el.value;
}

export { useForm };
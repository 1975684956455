//#region background images
export const bgUrl = (imageUrl) => {
  return `background-image:url("` + encodeURI(decodeURI(imageUrl)) + `")`
}

export const bgUrlStyle = (imageUrl) => {
  return `url("` + encodeURI(decodeURI(imageUrl)) + `")`
}
//#endregion

//#region screen/element size
export const screenWidth = () => {
  if (typeof window !== `undefined`) { 
    return window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  } else {
    return 0;
  }
}

export const screenHeight = () => {
  if (typeof window !== `undefined`) { 
    return window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
  } else {
    return 0;
  }
}

export const outerWidth = el => el.offsetWidth
export const outerHeight = el => el.offsetHeight
//#endregion

//#region css class
/**
 * Check if element has the css class on it.
 */
export const hasClass = (el, className) => {
  if (el.classList) {
    return el.classList.contains(className)
  }

  return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`))
}

/**
 * Adds the provided css className to the element.
 */
export const addClass = (el, className) => {
  if (el.classList) {
    el.classList.add(className)
    return
  }

  if (!hasClass(el, className)) {
    el.className += ` ${className}`
  }
}

/**
 * Remove the provided css className from the element.
 */
export const removeClass = (el, className) => {
  if (el.classList) {
    el.classList.remove(className)
    return
  }

  if (hasClass(el, className)) {
    const reg = new RegExp(`(\\s|^)${className}(\\s|$)`)
    el.className = el.className.replace(reg, ' ')
  }
}

/**
 * Adds or removes a class name on the input depending on the status flag.
 */
export const toggleClass = (el, className, status) => {
  if (!el || !className) return

  if (status) {
    return addClass(el, className)
  }

  removeClass(el, className)
}
//#endregion
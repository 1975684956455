export function formatMoney(number, fraction = 0) {
  var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: fraction,
  });

  return formatter.format(number);
}

export function toMonthlyCost(amount, frequency) {
  if (!amount) return 0;
  if (frequency === 'Week') {
    return amount * 52.1429 / 12.0;
  } else if (frequency === 'Fortnight') {
    return amount * 52.1429 / 2.0 / 12.0;
  } else if (frequency === 'Month') {
    return amount;
  } else if (frequency === 'Quarter') {
    return amount * 4.0 / 12.0;
  } else if (frequency === 'Year') {
    return amount / 12.0;
  } else {
    return amount;
  }
}

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export function scrollTo(selector, duration = 750, delay = 0) {
  const el = findElement(selector);
  if (el) {
    setTimeout(() => {
      //console.log(element);
      const offset = elementOffset(el);
      //console.log(offset)
      //window.scrollTo(0, offset.top - 300);

      gsap.to(window, {duration: (duration/1000), scrollTo: offset.top - 100, ease: 'power1'});

    }, delay);
      //el.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' }); }, timeout);
    return true;
  }
  return false;
}

export function findElement(selector) {
  if (typeof selector !== 'string') return selector;

  let found = querySelectorOrNull(selector)
    || querySelectorOrNull('#' + selector)
    || querySelectorOrNull('.' + selector)
  
  return found;
}

export function querySelectorOrNull(selector) {
  try {
    return document.querySelector(selector);
  } catch(e){
    return null;
  };
}

export function elementOffset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = typeof window !== `undefined` ? (window.pageXOffset || document.documentElement.scrollLeft) : 0,
    scrollTop = typeof window !== `undefined` ? (window.pageYOffset || document.documentElement.scrollTop) : 0;

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft, width: el.offsetWidth, height: el.offsetHeight }
}

export function isElementInViewport (el) {
  if (typeof window !== `undefined`) {
    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  } else {
    return true;
  }
}

export function getCurrentBreakpoint() {
  if (typeof window === `undefined`) return null;
  
  const bpHolder = document.querySelector('.devMediaQueryStatus__current');
  if (!bpHolder) return null;
  return window.getComputedStyle(bpHolder, ':before').getPropertyValue('content').replace(/["']/g, '');
}


//#region error json parsing
export const getErrors = (result) => {
  let errors = [];
  if (result && result.errors && result.errors.length > 0) {
    result.errors.forEach(err => {
      errors.push(err.errorMessage);
    });
  }
  return errors;
}

export const getErrorMessage = (result) => {
  let errors = getErrors(result);
  return errors.join(" ");
}
//#endregion
import { useLayoutEffect } from 'react';
//import useWindowScroll from '@react-hook/window-scroll';
import { au } from '../../components';

/* background image height =
  + cutoff padding = always hidden padding - usually 10 px
    + panel height (desired parallax panel height)
    + padding height = panel height * padding height ratio
  + cutoff padding = always hidden padding - usually 10 px
*/

const defaultSettings = {
  cutoffPadding: 10,
  speed: 15, // 5% of image/100px scroll
  //paddingHeightRatio: 0.3
  //screenSize
}

export const ParallaxData = {
  settings: {
    screenSize: null
  },
  panels: [
    { selector: '.parallax', imageRatio: 1080 / 1920, panelHeight: 'auto' },
  ],
  elements: [],
  timeoutId: null
}

export function useParallax() {
  const handleScroll = () => {
    //console.log('useParallax - scrolling')
    onScroll(ParallaxData);
  }

  const reset = () => {
    //console.log('useParallax - reset');
    setTimeout(() => { init(ParallaxData); handleScroll(); }, 300)
  }

  useLayoutEffect(() => {
    //console.log('useParallax - effect');
    setTimeout(() => { init(ParallaxData); handleScroll(); }, 300)
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', reset)
    }
    return () => {
      //console.log('useParallax - removed');
      if (typeof window !== `undefined`) {
        window.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', reset)
      }
    }

    // eslint-disable-next-line 
  }, []);

  return { reset };
};

// export function useParallax(opt) {
//   // const settings = Object.assign({}, defaultSettings, opt);

//   const [resetCounter, setResetCounter] = useState(0);
//   const [parallaxData,] = useState(ParallaxData);

//   //const scrollY = useWindowScroll(60 /*fps*/)
//   const reset = () => {
//     setResetCounter(resetCounter + 1);
//   }

//   const handleScroll = () => {
//     onScroll(parallaxData);
//   }

//   useLayoutEffect(() => {
//     console.log('useParallax - effect');

//     setTimeout(() => { init(parallaxData); handleScroll(); }, 300)

//     //const handleScroll = (): void => setThrottledScroll(getScrollY())
//     window.addEventListener('scroll', handleScroll)
//     window.addEventListener('resize', handleScroll)
//     return (): void => {
//       window.removeEventListener('scroll', handleScroll)
//       window.removeEventListener('resize', handleScroll)
//     }

//     // eslint-disable-next-line 
//   }, [resetCounter]);
//   //console.log(scrollY, resetCounter)

//   return { reset };
// };

export function init(data) {
  //console.log('parallax init');
  const screenSize = data.settings.screenSize = au.getScreenSize();
  data.settings = Object.assign({}, defaultSettings, data.settings)
  let cnt = 0;
  data.panels.forEach(panel => {
    const elements = document.querySelectorAll(panel.selector) || [];
    for (let i = 0; i < elements.length; i++) {
      cnt++;
      const el = elements[i];
      //const el = document.querySelector(panel.selector);
      //console.log(panel.selector, el)
      //if (!el) { panel.el = null; return; }
      const autoPanelHeight = !panel.panelHeight || panel.panelHeight === 'auto';
      const panelHeight = autoPanelHeight ? el.offsetHeight : parseFloat(panel.panelHeight);
      //console.log(autoPanelHeight, panelHeight)

      const calculated = { shadowHeight: 0, shadowWidth: 0, fromTop: 0, toTop: 0 };
      // calculated.shadowHeight = panelHeight * (1 + data.settings.paddingHeightRatio) + 2 * data.settings.cutoffPadding;
      // calculated.shadowWidth = calculated.shadowHeight / panel.imageRatio;
      // if (calculated.shadowWidth < data.settings.screenSize.width) {
      //   // magnifying the background image to fix the full width of the browser
      //   calculated.shadowWidth = data.settings.screenSize.width;
      //   calculated.shadowHeight = calculated.shadowWidth * panel.imageRatio;
      // }

      const speed = data.settings.speed * (screenSize.width > 1000 ? 1 : 2) 
      let baseHeight = data.settings.screenSize.width * panel.imageRatio > panelHeight ? data.settings.screenSize.width * panel.imageRatio : panelHeight
      baseHeight = baseHeight + (data.settings.screenSize.height + panelHeight) * (speed * 0.01)
      calculated.shadowHeight = baseHeight + 2 * data.settings.cutoffPadding;
      calculated.shadowWidth = calculated.shadowHeight / panel.imageRatio;
      if (calculated.shadowWidth < data.settings.screenSize.width) {
        // magnifying the background image to fix the full width of the browser
        calculated.shadowWidth = data.settings.screenSize.width;
        calculated.shadowHeight = calculated.shadowWidth * panel.imageRatio;
      }

      calculated.fromTop = -data.settings.cutoffPadding;
      calculated.toTop = -calculated.shadowHeight + panelHeight + data.settings.cutoffPadding;

      if (!autoPanelHeight)
        (el).style.height = panel.panelHeight + 'px';

      (el).style.backgroundSize = calculated.shadowWidth + 'px ' + calculated.shadowHeight + 'px'

      //panel.el = el;
      panel.calculated = calculated;
      data.elements.push({ el: el, calculated: calculated });
      //console.log(panelHeight, baseHeight, calculated)
    }
  })

  //console.log('parallax init - ' + cnt)
  if (cnt === 0) {
    if (data.timeoutId) {
      try {
        if (typeof window !== `undefined`) { 
          window.clearTimeout(data.timeoutId)
        }
      } catch(e){}
    }
    // reschedule
    if (typeof window !== `undefined`) { 
      data.timeoutId = window.setTimeout(() => init(data), 500)
    }
  }
}


export function onScroll(data) {
  //console.log(data);
  data.elements.forEach(element => {
    if (!element.el) return;
    const pr = au.getScrollProgress(element.el).progress;
    //console.log(pr);

    const min = element.calculated.fromTop;
    const max = element.calculated.toTop;
    const top = au.prVal(min, max, pr, [0, 1]);
    //console.log(top);
    (element.el).style.backgroundPositionY = top + 'px'
  })

}
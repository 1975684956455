import utils from '../../utils';

export function hasRequiredValidator(control) {
  if (!control) return false;
  return !!control.findValidator('required')
}

export function getNameForValidator(label, name) {
  return utils.text.toFieldName(label || name);
}

export function getValidatorOptions(params) {
  const defaultOpt = { continueToValidate: false }
  return params ? { ...defaultOpt, ...params } : defaultOpt;
}

export function createValidator(type, message, opt, validate) {
  const validator =
  {
    type: type,
    disabled: false,
    ...getValidatorOptions(opt),
    message: message,
    validate: validate
  }
  return validator;
}
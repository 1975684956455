import env from '../../env';

export const pageView = (url) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(`Tracking is deprecated.`, `Use seperate tracking helper instead`);
  }
  if (hasGTM()) gtmPageView(url)
  if (hasGtag()) gaPageView(url)
  if (hasFBPixel()) fbPageView(url)
}

export const sendFormEvent = (formName, params) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(`Tracking is deprecated.`, `Use seperate tracking helper instead`);
  }
  const p = Object.assign({ 'gtm.elementId': formName }, params || {})

  if (hasGTM()) gtmSendEvent('form', p)
  if (hasGtag()) gaSendEvent('form', p)
  if (hasFBPixel()) fbSendEvent('form', p)
}

export const sendEvent = (eventName, params) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(`Tracking is deprecated.`, `Use seperate tracking helper instead`);
  }
  if (hasGTM()) gtmSendEvent(eventName, params)
  if (hasGtag()) gaSendEvent(eventName, params)
  if (hasFBPixel()) fbSendEvent(eventName, params)
}

//#region GTM
export const hasGTM = () => {
  return !!(typeof window !== `undefined` && window.dataLayer);
}

export const gtmPageView = (url) => {
  if (typeof window !== `undefined`) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: url
      }
    })
  }
}
export const gtmSendEvent = (eventName, params) => {
  if (typeof window !== `undefined`) {
    window.dataLayer.push(Object.assign({ event: eventName }, params || {}))
  }
}
//#endregion

//#region Gtag / GA
export const hasGtag = () => {
  return !!(typeof window !== `undefined` && window.gtag);
}

export const gaPageView = (url) => {
  if (typeof window !== `undefined`) {
    if (env.gaAccounts) {
      env.gaAccounts.forEach(id => {
        window.gtag('config', id, { 'page_path': url });
      })
    }
  }
}

export const gaSendEvent = (eventName, params) => {
  if (typeof window !== `undefined`) {
    if (env.gaAccounts && env.gaAccounts.length > 0) {
      window.gtag('event', eventName, params || {});
    }
  }
}
//#endregion

//#region Facebook Pixel
export const hasFBPixel = () => {
  return !!(typeof window !== `undefined` && window.fbq);
}

export const fbPageView = (url) => {
  if (typeof window !== `undefined`) {
    window.fbq('track', 'PageView');
  }
}

export const fbSendEvent = (eventName, params) => {
  if (typeof window !== `undefined`) {
    window.fbq('track', eventName, params || {});
  }
}
//#endregion




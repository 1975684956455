import React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel } from '../formRenderHelper';

const CheckboxListInput = ({ control, handleChange, formOption }) => {
    const { name, label, value, errors, style, options, helpText, attrs } = control;
    const fieldId = utils.random.shortId();
    const fieldLabel = getLabel(control, formOption);
    const values = value ? [...value] : [];
    return (
        <div className={`ff ff--checkbox form-group ${style && style.className}`}>
            {fieldLabel && <label className="label">{fieldLabel}</label>}
            <div className="ff__input">
                <div className="controlOptions">
                    {options && options.map((option, index) =>
                        <div className={`form-check ${style && style.inline && 'form-check-inline'}`} key={option.value} >
                            <input className="form-check-input" name={name} type="checkbox"
                                checked={checked(values, option.value)}
                                onChange={(e) => handleChange(e, control)}
                                value={option.value} id={fieldId + '_' + index} {...attrs}/>
                            <label className="form-check-label" htmlFor={fieldId + '_' + index}>
                                {option.name}
                            </label>
                        </div>)}

                </div>
            </div>
            {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
            <ValidationAlert errors={errors} />
        </div>
    )
}

function checked(values, value) {
    if (value === null || typeof value === 'undefined') return false;
    return values.includes(value);
}

export default CheckboxListInput;
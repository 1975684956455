import * as React from 'react';
import utils from '../../../utils';

function TextInput({ control, handleChange, formOption }) {
    const { name, value } = control;
    const fieldId = utils.random.shortId();
    let valueStr = value === 0 ? 0 : (value || "");


    return (
        <input id={fieldId} name={name} type="hidden" value={valueStr} />
    )
}

export default TextInput;
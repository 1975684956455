import { createValidator, getNameForValidator } from '../validatorHelper';

export function Email(message = null, opt = null) {
  return createValidator('email', message, opt, function(control, e, validateOpt) {
    if (validateOpt.triggeredByChange && control.incomplete) return null;
    // only validate for blur keydown tab
    if (e && e.type === 'change') return null;
    return !isEmpty(control.value) && !isValidEmail(control.value) ? (message || (getNameForValidator(control.label, control.name) + ' - Invalid email.')) : null
  })
}


export const isValidEmail = mail => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(mail);
}

function isEmpty(val) {
  if (Array.isArray(val))
    return val.length === 0;
  return !val && val !== 0;
}

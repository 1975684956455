import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { initialState, reducer } from '../../store';

const StoreContext = createContext(initialState);

const StoreProvider = ({ children }) => {
  // Get state and dispatch from Reacts new API useReducer. 
  const [state, dispatch] = useReducer(reducer, initialState);
  //console.log('StoreProvider')
  // Log new state
  useEffect(() => {
    //console.log('-- new state', state);
    //console.trace();
  }, [state]);
  // Render state, dispatch and special case actions
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};
export { StoreContext, StoreProvider };

export const useStore = () => useContext(StoreContext);